body {
  margin: 0;
  padding: 0;
  font-family: 'Fira Code', monospace;
  background-color: #1e1e1e;
  color: #fff;
}

.ant-card {
  border-radius: 10px;
}

.ant-card-cover {
  background-color: #2d2d2d;
}

.ant-card-meta-title {
  color: #fff !important;
}

.ant-card-meta-description {
  color: #aaa !important;
}
